/* eslint-disable prefer-rest-params */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { clsx, type ClassValue } from "clsx";
import { extendTailwindMerge } from "tailwind-merge";
import tailwindConfig from "../../tailwind.config";

export const twMerge = extendTailwindMerge({
  extend: {
    classGroups: {
      "font-size": Object.keys(tailwindConfig.theme?.fontSize || []).map((key) => "text-" + key),
      "font-weight": Object.keys(tailwindConfig.theme?.fontWeight || []).map((key) => "font-" + key),
    },
  },
});

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

// type ValidationType = "partial" | "full";
// export function chainValidators(validators: ((value: string) => boolean)[], validationType: ValidationType = "full") {
//   return (value: string) => {
//     switch (validationType) {
//       case "partial":
//         return validators.some((validator) => validator(value));
//       case "full":
//         return validators.every((validator) => validator(value));
//     }
//   };
// }
