import { Locale } from "@/i18n.config";
import { create } from "zustand";

interface ILocaleStore {
  locale: string;
  setLocale: (locale: Locale) => void;
}
interface IBreadcrumbsLabel {
  branchName: string;
  unitNumber: string;
  setBranchName: (branchName: string) => void;
  setUnitNumber: (unitNumber: string) => void;
}
interface IContentfulBreadcrumb {
  breadcrumbs: { id: string; key: string; value: string }[];
  pathname: string;
}
interface IContentfulBreadcrumbs {
  contentfulBreadcrumbs: IContentfulBreadcrumb | null;
  setContentfulBreadcrumbs: (contentfulBreadcrumbs: IContentfulBreadcrumb) => void;
}

interface ISaleUnitType {
  unitTypeStore: string;
  setUnitTypeStore: (unitTypeStore: string) => void;
}

export const useLocale = create<ILocaleStore>((set) => ({
  locale: "en",
  setLocale: (locale: Locale) => set(() => ({ locale })),
}));

export const useBreadcrumbsLabel = create<IBreadcrumbsLabel>((set) => ({
  branchName: "",
  unitNumber: "",
  setBranchName: (branchName: string) => set(() => ({ branchName })),
  setUnitNumber: (unitNumber: string) => set(() => ({ unitNumber })),
}));

export const useSaleUnitType = create<ISaleUnitType>((set) => ({
  unitTypeStore: "",
  setUnitTypeStore: (unitTypeStore: string) => set(() => ({ unitTypeStore })),
}));

export const useContentfulBreadcrumbs = create<IContentfulBreadcrumbs>((set) => ({
  contentfulBreadcrumbs: null,
  setContentfulBreadcrumbs: (contentfulBreadcrumbs: IContentfulBreadcrumb) => set(() => ({ contentfulBreadcrumbs })),
}));
