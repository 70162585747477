"use client";
import { cn } from "@/lib/utils";
import { useLocale } from "@/store/clientStore";
import Link from "next/link";

interface CustomLinkProps {
  href: string;
  children: React.ReactNode;
  className?: string;
  ariaLabel?: string;
  [key: string]: unknown;
}

/**
 * this is a custom link component that checks default language and redirects user to correct path
 * if the language is default, it won't inject locale at the beginning of the path-name
 */

export default function CustomLink({ href, className, ariaLabel, ...props }: CustomLinkProps) {
  const { locale } = useLocale();
  // ! this will be handled later
  // const isDefaultLang = lang === i18n.defaultLocale;
  // const path = isDefaultLang ? href : `/${lang}${href}`;

  if (typeof href === "undefined" || `${href}` === "" || `${href}` === "-") {
    return (
      <a
        {...props}
        {...(ariaLabel && { "aria-label": ariaLabel })}
        target={`${href}`.startsWith("mailto:") || `${href}`.startsWith("tel:") ? "" : "_blank"}
        className={cn("focus-visible:outline-royal-blue outline-none cursor-pointer", className)}
      />
    );
  }

  if (
    `${href}`.startsWith("https://") ||
    `${href}`.startsWith("http://") ||
    `${href}`.startsWith("mailto:") ||
    `${href}`.startsWith("tel:")
  ) {
    return (
      <a
        href={href}
        {...(ariaLabel && { "aria-label": ariaLabel })}
        {...props}
        target={`${href}`.startsWith("mailto:") || `${href}`.startsWith("tel:") ? "" : "_blank"}
        className={cn("focus-visible:outline-royal-blue outline-none cursor-pointer", className)}
      />
    );
  }

  const _lang = locale || "en";

  const path = `/${_lang}${href}`;

  return (
    <Link
      aria-label={ariaLabel}
      prefetch={false}
      href={path}
      {...props}
      className={cn("focus-visible:outline-royal-blue outline-none cursor-pointer", className)}
    />
  );
}
