// HEADINGS ----------------------------------------------------------------
export const HEADLINE_LARGE =
  "font-sairaSemiCondensed lg:text-56 text-44 leading-[1.2] text-black font-[500] tracking-[-0.22px] lg:tracking-[-0.28px] break-words";

export const HEADLINE_MEDIUM =
  "font-sairaSemiCondensed lg:text-44 text-32 leading-[1.2] text-black font-[500] lg:tracking-[-0.22px] tracking-[-0.16px] break-words";

export const HEADLINE_SMALL =
  "font-sairaSemiCondensed text-32 leading-[1.2] text-black font-[500] tracking-[-0.16px] break-words";

export const TITLE_LARGE =
  "font-sairaSemiCondensed text-24 lg:text-28 leading-[1.5] tracking-[1px] lg:tracking-[0.56px] text-black font-[600] uppercase break-words";

export const TITLE_MEDIUM =
  "font-sairaSemiCondensed lg:text-24 text-20 lg:leading-[1.5] leading-[1.6] text-black font-[600] lg:tracking-[1px] tracking-[0.5px] uppercase break-words";

export const TITLE_SMALL =
  "font-sairaSemiCondensed lg:text-20 text-16 leading-[1.5] lg:leading-[1.6] text-black font-[600] tracking-[0.5px] uppercase break-words";

export const TITLE_X_SMALL =
  "font-sairaSemiCondensed text-16 leading-[1.5] text-black font-[600] tracking-[0.5px] uppercase break-words";

// SUB HEADINGS -------------------------------------------------------------
export const SUB_HEADING_LARGE =
  "font-sairaSemiCondensed lg:text-28 text-24 lg:leading-[1.42] leading-[1.5] lg:tracking-[-0.028px] tracking-[-0.1px] text-black font-[500] break-words";

export const SUB_HEADING_MEDIUM =
  "font-sairaSemiCondensed lg:text-24 text-20 lg:leading-[1.5] leading-[1.6] text-black font-[500] tracking-[-0.1px] break-words";

export const SUB_HEADING_SMALL =
  "font-sairaSemiCondensed text-20 leading-[1.6] text-black font-[500] tracking-[-0.1px] break-words";

// PARAGRAPHS -------------------------------------------------------------
export const BODY_LARGE_EMPHASIS =
  "font-saira text-18 leading-[1.54] text-black tracking-[-0.1px] font-[500] break-words";

export const BODY_LARGE = "font-saira text-18 leading-[1.77] text-black font-[400] tracking-[-0.1px] break-words";

export const BODY_MEDIUM_EMPHASIS =
  "font-saira text-16 leading-[1.75] text-black font-[500] tracking-[-0.1px] break-words";

export const BODY_MEDIUM = "font-saira text-16 leading-[1.75] text-black font-[400] tracking-[-0.1px] break-words";

export const BODY_SMALL_EMPHASIS =
  "font-saira text-14 leading-[1.7] text-black font-[500] break-words tracking-[-0.014px]";

export const BODY_SMALL = "font-saira text-14 leading-[1.7] text-black font-[400] break-words tracking-[-0.1px]";

export const BUTTON_MEDIUM = "font-sairaSemiCondensed text-16 leading-[1.5] text-black font-[500] break-words";

export const BUTTON_SMALL = "font-sairaSemiCondensed text-14 leading-[1.7] text-black font-[500] break-words";

export const LABEL_LARGE =
  "font-sairaSemiCondensed text-14 leading-[1.4] text-black font-[500] tracking-[1.5px] uppercase break-words";

export const LABEL_EMPHASIS = "font-sairaSemiCondensed text-14 leading-[1.6] text-black font-[500] break-words";

export const CAPTION_MEDIUM =
  "font-saira text-14 leading-[1.7] text-black font-[400] italic tracking-[-0.1px] break-words";
